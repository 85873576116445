:root {
  --tlo: #2C3639;
  --loader: #3F4E4F;
  --akcenty: #A27B5C;
  --teksty: #DCD7C9;
} 

/* WERSJA JASNA STRONY */
/* @media (prefers-color-scheme: light) {
  :root {
      --tlo: #DCD7C9;
      --loader: #3F4E4F;
      --akcenty: #A27B5C;
      --teksty: #2C3639;
  } 
} */

/* WERSJA CIEMNA STRONY */
/* @media (prefers-color-scheme: dark) {
  :root {
  --tlo: #2C3639;
  --loader: #3F4E4F;
  --akcenty: #A27B5C;
  --teksty: #DCD7C9;
  }
} */

body {
  font-family: 'Poppins', sans-serif !important;
}

body::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

body::-webkit-scrollbar-button {
  width: 16px;
  height: 16px;
}

body::-webkit-scrollbar-thumb {
  border-radius: 56px;
}

body::-webkit-scrollbar-track {
  border-radius: 0px;
}

body::-webkit-scrollbar-corner {
  background: transparent;
}

.ksiazka-title-link {
  text-decoration: none;
}

.grcovfix {
  border-radius: 0 6% 6% 0/4%;
  overflow: hidden;
  filter: drop-shadow(0 0.2rem 0.8rem rgba(0, 0, 0, 0.2));
  transition: all ease 0.2s;
}

.dp-series-link .grcovfix:hover, .ksiazka-item-left-part img:hover {
  transform: scale(1.04);
}

.header-box {
  text-align: center;
  height: 50px;
  line-height: 50px;
  FONT-WEIGHT: 500;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-size: 1.2rem;
  max-width: 1320px;
  z-index: 1000;
}

.footer-box {
  text-align: center;
  height: 50px;
  line-height: 50px;
  FONT-WEIGHT: 500;
  text-transform: uppercase;
  font-size: 1rem;
  max-width: 1320px;
  z-index: 1000;
}

.js-example-basic-single, .js-example-basic-single2, .js-example-basic-single3 {
  min-width: 33%;
  max-width: 100%;
}

.select2-container--default .select2-selection--single {
  border-radius: 0px;
  height: 35px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 35px;
  font-weight: 500;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  height: 35px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 35px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}

.select2-dropdown {
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-results__options::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.select2-results__options::-webkit-scrollbar-button {
  width: 16px;
  height: 16px;
}
.select2-results__options::-webkit-scrollbar-thumb {
  border-radius: 56px;
}

.select2-results__options::-webkit-scrollbar-track {
  border-radius: 0px;
}

.select2-results__options::-webkit-scrollbar-corner {
  background: transparent;
}

.flink {
  position: absolute;
  width: 100px;
  display: inline-block;
  height: 0px;
  left: 0;
  top: 0;
}

.blinkspan {
  padding-left: 2px;
  padding-right: 2px;
}

.blinkspan i{
  margin-left: 5px;
}

.blinkspan:hover, .blinkspan:active, .blinkspan:focus {
  color: var(--teksty);
}

.ksiazka-opis-cont-trig:hover {
  cursor: pointer;
  color: var(--teksty);
  background-position: left;
}

.ksiazka-item {
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 100%;
  margin-bottom: 20px;
  display: inline-block;
}

.ksiazka-item-onlybord  {
  height: 100%;
  width: 100%;
  padding-bottom: 10px;
  display: inline-block;
}

.ksiazka-item-onlybord-aut {
  height: 100%;
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2px;
  padding-right: 2px;
  padding-left: 2px;
}

.ksiazka-item-left-part {
  width: 17%;
  display: block;
  float: left;
  height: 155px;
}

.ksiazka-item-right-part {
  width: 58%;
  margin-left: 3%;
  float: left;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  flex:1;
  height: 100%;
}

.ksiazka-item-download-part {
  width: 19%;
  margin-left: 3%;
  display: block;
  float: left;
}

.ksiazka-item-left-part img {
  margin-left: 5%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.ksiazka-title, .pop-title {
  font-size: 1.125rem;
  line-height: 1.135rem;
  margin-bottom: 5px;
  display: block;
  margin-top: 0;
  width: 100%;
}

.ksiazka-autor, .pop-autor{
  display: inline-block;
  font-size: .8125rem;
  margin-bottom: 5px;
  width: 100%;
}

.ksiazka-cykl, .pop-series {
  font-size: .875rem;
  margin-bottom: 5px;
}

.sortmelikeboss {
  text-align: right;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 10px;
  line-height: 30px;
}

.sortmelikeboss select {
  margin-right: 1%;
  border-radius: 0px;
  height: 35px;
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.ksiazka-item-download-button {
  font-size: .875rem;
  width: 100%;
  display: block;
  float: right;
  line-height: 30px;
  text-align: center;
  margin-bottom: 10px;
  text-transform: uppercase;
  text-decoration: none;
  background-size: 200%;
  transition: .15s ease-out;
}

.ksiazka-item-download-button:hover {
  background-position: left;
  color: var(--teksty);
}

.ksiazka-item-download-button i {
  margin-right: 6px;
}

.ksiazka-opis-cont {
  display: none;
  text-align: justify;
  clear: both;
  padding-top: 20px;
  margin-left: 1%;
}

.ksiazka-opis-cont-trig {
  cursor: pointer;
  display: inline-block;
  width: auto;
  margin-bottom: 0;
  text-decoration: none;
  background-size: 200%;
  transition: .15s ease-out;
}

.ksiazka-tagi, .pop-tag, .ksiazka-data, .pop-datawydania, .pop-jezyk {
  font-size: .875rem;
  margin-bottom: 5px;
}

.ksiazka-tagi {
  padding-top: 5px;
  margin-top: 0px;
  position: relative;
}

.pop-tag {
  margin-bottom: 0;
}

.zo {
  display: none;
}

.ro i {
  margin-left: 4px;
}

.zo i {
  margin-left: 4px;
}

.aname {
  font-weight: 600;
  text-transform: uppercase;
}

.aditcompo{
  position: fixed;
  width: 100%;
  top: 50px;
  padding-top: 20px;
  max-width: 856px;
}

.upperpart {
  justify-content: normal;
}

@media (max-width: 575px) {
  .aditcompo {
      position: initial;
      top: 0;
      padding-top: 0;
  }
}

.pagerbox {
  text-align: center;
}

.pagerbox p span {
  font-weight: 500;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: inline-block;
}

.currpage {
  cursor: initial !important;
}

.element_to_pop_up {
  padding: 20px;
  padding-right: 10px;
  width: 90vw;
  height: 80vh;
  margin-top: 5vh;
}

.closeme {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-weight: 600;
  position: absolute;
  right: -15px;
  top: -15px;
  cursor: pointer;
}

.pop-inner {
  height: 100%;
  display: inline-block;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 10px;
  width: 100%;
}

.pop-inner::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.pop-inner::-webkit-scrollbar-button {
  width: 16px;
  height: 16px;
}

.pop-inner::-webkit-scrollbar-thumb {
  border-radius: 56px;
}

.pop-inner::-webkit-scrollbar-track {
  border-radius: 0px;
}

.pop-inner::-webkit-scrollbar-corner {
  background: transparent;
}

.pop-top {
  width: 100%;
  display: inline-block;
  margin-bottom: 15px;
  position: relative;
}

.pop-top-img {
  width: 25%;
  float: left;
  display: block;
}

.pop-top-img img {
  width: 90%;
  float: left;
  height: auto;
}

.pop-top-intro {
  width: 74%;
  float: right;
  position: absolute;
  bottom: 0;
  left: 26%;
}

.pop-datawydania, .pop-tag, .pop-title, .pop-autor, .pop-series, .pop-jezyk {
  margin-top: 0;
  margin-bottom: 0;
}

.pop-top-intro p {
  margin-top: 5px;
}

.pop-tag {
  margin-top: 15px !important;
  position: relative;
  padding-top: 5px;
}

.liczks {
  text-align: center;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 10px;
  line-height: 30px;
}

.liczkslicz {
  font-weight: 500;
}

.licz {
  margin-top: 10px;
}

.pop-links {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.pop-links a {
  width: 20%;
  float: initial;
  margin-left: 1%;
  margin-right: 1%;
}

.bonuskliks {
  text-align: center;
}

.sortmelikeboss {
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.szukklas {
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.szukklas i {
  float: left;
  line-height: 30px;
}

.clearsearch {
  cursor: pointer;
  display: none;
}

.searchmebox {
  border:  solid 1px transparent;
  max-width: 80%;
  display: inline-block;
  height: 30px;
  cursor: pointer;
  padding-left: 3px;
  margin-left: 3px;
}

.searchmebox:focus{
  outline: none;
}

.searchmebox:hover{
  outline: none;
}

#nobookerror {
  display: none;
  text-align: center;
  margin-top: 10px;
}

.hidelink {
  color: initial;
  text-decoration: initial;
  background-size: 200%;
  transition: .15s ease-out;
}

.hidelink:hover {
  background-position: left;
  color: var(--teksty);
}

.ksiazka-autor span, .ksiazka-cykl span, .ksiazka-autor-klik-link, .cyklklik, .cyklklik-link {
  cursor: pointer;
  text-decoration: none;
  background-size: 201%;
  transition: .15s ease-out;
}

.ksiazka-autor span:hover, .ksiazka-cykl span:hover, .ksiazka-autor-klik-link:hover, .cyklklik:hover, .cyklklik-link:hover {
  cursor: pointer;
  background-position: left;
  color: var(--teksty);
}

.langdiv {
  margin-left: 5px;
  margin-right: 5px;
}

.langflag {
  max-height: 1.3rem;
  padding: 1px;
  margin-left: 5px;
}

.aut-ser-list {
  color: initial;
}

.aut-licznik-ksiazek a {
  text-decoration: none;
  font-weight: bold;
}

.aut-name {
  margin-bottom: 0;
  font-size: 1.4em;
  line-height: 1.1em;
}

.aut-name-list {
  margin-bottom: 0;
  font-size: 1em;
  line-height: 0.9em;
}

.autor-list-item-inner-board {
  padding-left: 5px;
  padding-right: 5px;
}

.aut-tagi {
  margin-bottom: 0px;
  margin-top: 5px;
}

.autorzy-main-letter p {
  text-transform: uppercase;
  font-size: 1.4em;
  font-weight: 500;
  padding-right: 5px;
  padding-left: 5px;
  width: auto;
  display: inline-block;
}

.aut-surname {
  margin-bottom: 0;
  font-size: 2.6em;
  font-weight: 600;
  line-height: 1em;
}

.aut-surname-list {
  margin-bottom: 0;
  font-size: 1.6em;
  font-weight: 600;
  line-height: 1.2em;
}

.aut-name-surname {
  margin-bottom: 10px;
}

.literkowalista {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.literkowalista a {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 3px;
  margin-bottom: 3px;
  text-decoration: none;
  text-align: center;
  line-height: 40px;
  font-weight: 600;
  background-size: 200%;
  transition: .15s ease-out;
}

.literkowalista a:hover {
  background-position: left;
}

.autorzylink {
  color: initial;
  text-decoration: none;
  background-size: 200%;
  transition: .15s ease-out;
}

.autorzylink:hover {
  background-position: left;
  text-decoration: none;
  color: var(--teksty) !important;
}

.autorzylink:hover .aut-surname-list{ 
  color: var(--teksty) !important;
} 

.onlylinkgr {
  font-size: 0.9em;
  margin: 0;
  margin-top: 3px;
  color: initial;
  text-decoration: none;
  background-size: 200%;
  transition: .15s ease-out;
}

.onlylinkgr:hover {
  background-position: left;
  text-decoration: none;
}

.aut-info-row {
  padding-bottom: 15px;
  margin-bottom: 20px;
}

@media (max-width: 575px) {
  .pop-top-img {
      width: 100%;
      float: left;
      display: block;
      margin-bottom: 15px;
  }

  .pop-top-img img {
      width: 100%;
      float: left;
      height: auto;
  }

  .pop-top-intro {
      width: 100%;
      float: left;
      position: initial;
  }

  .ksiazka-item-left-part {
      width: 25%;
      height: 150px;
  }
  .ksiazka-item-right-part {
      width: 72%;
      margin-left: 3%;
      height: auto;
      min-height: 155px;
  }

  .ksiazka-item-download-part {
      width: 100%;
      margin-top: 10px;
      margin-left: 0;
  }

  .ksiazka-item-download-button {
      width: 31%;
      text-align: center;
      margin-bottom: 3px;
      margin-right: 2%;
  }

  .ksiazka-item {
      margin-bottom: 0;
      padding-bottom: 0;
  }

  .pagerbox {
      margin-top: 20px;
  }

  .ksiazka-opis-cont-trig {
      margin-top: 20px;
      margin-bottom: 10px;
      width: 97%;
      text-align: center;
      line-height: 30px;
      font-size: .875rem;
      margin-right: 1%;
      margin-left: 1%;
  }
  
  .ro {
      width: 100%;
      display: inline-block;
      text-align: center;
  }

  .sortmelikeboss {
      text-align: center;
  }
}


@media (max-width: 990px) {
  .js-example-basic-single, .js-example-basic-single2, .js-example-basic-single3 {
      width: 100%;
      max-width: 100%;
      margin-bottom: 15px;
  }

  .select2-container {
      margin-bottom: 15px;
  }

  .pop-links a {
      width: 100%;
      margin-bottom: 15px;
      float: initial;
  }

  .szukklas i {
      font-size: 20px;
  }

  .select2-container--default .select2-selection--single .select2-selection__clear {
      height: 35px;
      line-height: 30px;
      font-size: 25px;
  }
}

.dp-container-bot {
  width: 100%;
}

.dp-container-bot-fake-line {
  padding-bottom: 5px;
  padding-top: 7px;
  width: 50%;
}

.dp-cover {
  max-width: 100%;
  height: auto;
}

.dp-cover-container {
  text-align: center;
}

.dp-title {
  margin-top: 0px;
  font-size: 1.3em;
  margin-bottom: 0px; 
  font-weight: 600;
  padding-top: 20px;
}

.grubasek {
  font-weight: 600;
}

.dp-autor {
  font-size: 1em;
  margin-top: 0;
  margin-bottom: 25px;

}

.dp-series {
  font-size: 0.9em;
  margin-top: 0;
  margin-bottom: 0;
}

.dp-data {
  font-size: 0.9em;
  margin: 0;
  margin-top: 3px;
}

.dplangflag {
  max-height: 0.9rem;
  padding: 1px;
  margin-left: 5px;
  margin-top: -1px;
}

.dp-org-div {
  padding-left: 5px;
  padding-right: 5px;
}

.dp-lang {
  margin: 0;
}

.dp-tag {
  margin: 0;
  margin-top: 3px;
  font-size: 0.9em;
}

.dp-series-item-cover img {
  width: auto;
  height: 250px;
}

.dp-series-item-name {
  width: 100%;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 20px;
}

.dp-series-list-title {
  text-align: center;
}

.dp-series-list-title p {
  width: auto;
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 5px;
  margin-bottom: 25px;
  margin-top: 20px;
}

.dp-series-detail {
  margin-bottom: 40px;
}

.dp-series-item-name span, .dp-series-list-title span {
  font-weight: 600;
}

.dp-desc {
  text-align: justify;
  padding-top: 25px;
  margin-bottom: 40px;
}

.dp-series-link {
  text-decoration: none;
  color: initial;
}

.dp-series-item-cover {
  text-align: center;
}

.dp-down-cont {
  padding-top: 25px;
  width: 100%;
}

.grlogo {
  max-height: 25px;
  margin-bottom: 5px;
  margin-right: 8px;
}

.grlogoindex {
  max-height: 17px;
  margin-right: 2px;
}

.dp-rating {
  display: inline-block;
  margin: 0;
  margin-top: 5px;
  height: 25px;
  line-height: 25px;
  color: initial;
  text-decoration: none;
}

.grratingvalue {
  font-size: 20px;
}

.aut-pr {
  min-width: 15%;
  text-align: right;
}

.aut-book-counter {
  font-weight: 500;
}

.scrolltotopbox, .scrolltotopbo:active {
  display: inline-block;
  width: 45px;
  height: 45px;
  text-align: center;
  position: fixed;
  font-size: 35px;
  line-height: 47px;
  bottom: 10px;
  right: 10px;
  border-radius: 25px;
}

.masternavbar {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  flex-direction: row;
  padding-left: 0;
  padding-right: 0;
}

.masternavbar a {
  text-transform: lowercase;
  text-decoration: none;
  width: 100%;
  background-size: 200%;
  transition: .15s ease-out;

}

.masternavbar a:hover, .masternavbar a.activeasbitch {
  background-position: left;
  color: var(--teksty); 
}


@media (max-width: 550px) {
  .dp-cover {
      max-width: 300px;
  }

  .grlink-desktop {
      display: none;
  }

  .grlink-mobile {
      display: initial;
  }
}
 
.wngr {
  margin-right: 3px;
}

.GoodreadsWordmark {
  height: 16px;
  margin-bottom: 1px;
}

.dp-rating-line {
  margin: 0;
  margin-top: 3px;
  font-size: 1em;
}
.dp-rating-line a {
  display: inline-block;
  text-decoration: none;
  color: initial;
  line-height: 1em;
}

.dp-rating-logo {
  height: 1.2em;
  width: 1.2em;
  border-radius: 15%;
}

.nowaglownaocena1 {
  margin-right: 6px;
}

.nowaglownaocena img {
  height: 15px;
  margin-right: 3px;
  border-radius: 3px;
}

.dp-rating-total-1 {
  font-weight: 600;
}

.noc {
  font-weight: 600;
}   

.login-login-container {
  width: 100%;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 20vh;
}

.introtext {
  text-align: center;
  height: 50px;
  line-height: 50px;
  FONT-WEIGHT: 500;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-size: 1.2rem;
}

.login-login-login {
  font-size: .875rem;
  width: 60%;
  display: inline-block;
  font-weight: 800;
  line-height: 30px;
  text-align: center;
  margin-bottom: 10px;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 20px;
}

.login-login-pass {
  padding: 10px;
  max-width: 100%;
}

#autorzyselect, #serieselect, #tagiselect {
  opacity: 0;
}

.errorpass {
  font-weight: bold;
}
  
body {
  background-color: var(--tlo);
}
  
.introtext {
  border-bottom: solid 1px var(--akcenty);
  color: var(--akcenty);
}

.dplangflag {
  max-height: 0.9rem;
  border: solid 1px var(--akcenty);
  padding: 1px;
  margin-left: 5px;
  margin-top: -1px;
}

.login-login-pass {
  color: var(--teksty);
  background-color: var(--tlo);
  border: solid 1px var(--akcenty);
}

.login-login-login:hover {
  color: var(--akcenty);
  background-color: var(--teksty);
}

.errorpass {
  color: var(--akcenty);
  font-weight: bold;
}

.masternavbar a {
  color: var(--akcenty);
  border-bottom: solid 1px var(--akcenty);
  background: linear-gradient(to left, var(--tlo) 50%, var(--akcenty) 50%) right;
  background-size: 200%;
  transition: .15s ease-out;
}
  
.aut-name, .autorzy-main-letter p, .aut-name-list, .aut-book-counter, .aut-tagi, .aut-ser-list, .aut-serie-title, .ksiazka-title, .ksiazka-cykl, .ksiazka-tagi, .ksiazka-data, .liczks, .dp-desc, .dp-series-list-title p, .dp-series, .dp-tag, .dp-data, .dp-rating-line, .dp-rating-line a, .dp-series-item-name, .prevpage, .nextpage {
  color: var(--teksty);
}

.aut-surname, .liczkslicz, .noc, .langdiv, .currpage, .dp-rating-total-1, .dp-org-div, .aut-surname-list, .aut-book-counter i, .scrolltotopbox, .scrolltotopbo:active, .grocena, .grratingvalue, .szukklas i, #nobookerror, .select2-container--default .select2-selection--single .select2-selection__rendered, .select2-container--default .select2-selection--single .select2-selection__clear{
  color: var(--akcenty);
}

.scrolltotopbox:hover {
  color: #ffffffb0;
  background-color: var(--akcenty);
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: var(--akcenty);
  color: var(--teksty);
}
  
.aut-info-row, .licz, .dp-container-bot-fake-line, .dp-series-list-title p {
  border-bottom: solid 1px var(--akcenty);
}

.autorzy-main-letter p {
  border-bottom: 3px solid var(--akcenty);
}

.searchmebox:focus, .searchmebox:hover, .select2-dropdown, .select2-container--default .select2-search--dropdown .select2-search__field {
  border: solid 1px var(--akcenty);
}

.flink {
  border-top: solid 1px var(--akcenty);
}

.select2-results__options::-webkit-scrollbar-thumb {
  background: var(--akcenty);
  border: 0px none var(--teksty);
  border-radius: 56px;
}

body::-webkit-scrollbar-thumb {
  background: var(--akcenty);
  border: 0px none var(--teksty);
  border-radius: 56px;
}

.literkowalista a {
  color: var(--akcenty);
  background-color: var(--tlo);
  background: linear-gradient(to left, var(--tlo) 50%, var(--akcenty) 50%) right;
  background-size: 200%;
  transition: .15s ease-out;
}

.autorzylink {
  background: linear-gradient(to left, var(--tlo) 50%, var(--akcenty) 50%) right;
  background-size: 200%;
  transition: .15s ease-out;
}

.ksiazka-item-onlybord-aut {
  border-bottom: solid 1px var(--teksty);
}

.ksiazka-autor span, .ksiazka-cykl span, .ksiazka-autor-klik-link, .cyklklik, .cyklklik-link {
  color: var(--akcenty);
  background: linear-gradient(to left, var(--tlo) 50%, var(--akcenty) 50%) right;
  background-size: 201%;
  transition: .15s ease-out;
}
  
.ksiazka-item-onlybord {
  border-bottom: solid 1px var(--teksty);
}

.ksiazka-item-download-button {
  color: var(--akcenty);
  border: solid 2px var(--akcenty);
  background: linear-gradient(to left, var(--tlo) 50%, var(--akcenty) 50%) right;
  background-size: 200%;
  transition: .15s ease-out;
}

.ksiazka-opis-cont-trig {
  color: var(--akcenty);
  background: linear-gradient(to left, var(--tlo) 50%, var(--akcenty) 50%) right;
  background-size: 200%;
  transition: .15s ease-out;
  border: solid 2px var(--tlo);
}
  
.searchmebox {
  color: var(--teksty);
  background-color: var(--tlo);
}

.hidelink {
  color: var(--teksty);
  background: linear-gradient(to left, var(--tlo) 50%, var(--akcenty) 50%) right;
  background-size: 200%;
  transition: .15s ease-out;
}

.sortmelikeboss select {
  background-color: var(--tlo);
  color: var(--teksty);
  border: 1px solid var(--akcenty);
}

.select2-container--default .select2-selection--single {
  background-color: var(--tlo);
  border: 1px solid var(--akcenty);
}
  
.select2-results__option--selectable {
  background-color: var(--tlo);
  color: var(--teksty);
}

.select2-container--default .select2-results__option--selected {
  background-color: var(--tlo);
  color: var(--akcenty);
}

.select2-search--dropdown {
  background-color: var(--tlo);
} 

.select2-search--dropdown .select2-search__field {
  background-color: var(--tlo);
  color: var(--teksty);
}

.select2-results__option {
  background-color: var(--tlo);
  color: var(--teksty);
}

.masternavbar {
  background-color: var(--tlo);
}

.login-login-login {
  color: var(--teksty);
  background-color: var(--akcenty);
  border: solid 2px var(--akcenty);
}

.scrolltotopbox, .scrolltotopbo:active {
  background-color: var(--loader);
}

.literkowalista a:hover {
  color: var(--teksty);
}

.aut-surname-list {
  transition: all ease 0.2s;
}

.aut-surname-list:hover {
  color: var(--teksty);
}


.debugtext {
  color: #DCD7C9;
}

.autratadd {
  font-size: initial;
  margin-left: 0;
}

/* loader */

.lds-wrap {
    text-align: center;
    width: 100%;
    display: inline-block;
}

.loader {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  margin:15px auto;
  position: relative;
  color: var(--akcenty);
  box-sizing: border-box;
  animation: animloader 1s linear infinite alternate;
}

@keyframes animloader {
  0% {
    box-shadow: -38px -12px ,  -14px 0,  14px 0, 38px 0;
  }
  33% {
    box-shadow: -38px 0px, -14px -12px,  14px 0, 38px 0;
  }
  66% {
    box-shadow: -38px 0px , -14px 0, 14px -12px, 38px 0;
  }
  100% {
    box-shadow: -38px 0 , -14px 0, 14px 0 , 38px -12px;
  }
}

.dt {
  color: red;
}

.login-login-login {
  cursor: pointer;
}

.szukany-tekst {
  color: var(--teksty);
  text-align: right;
  WIDTH: 100%;
  display: inline-flex;
  align-content: center;
  HEIGHT: 100%;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.szukany-tekst b {
  margin-left: 5px;
}

.search-no-found {
  text-align: center;
    font-size: 1.3em;
    padding-top: 20px;
    color: var(--akcenty);
}

.sh {
  background-color: var(--akcenty);
  text-transform: uppercase;
}

#Search::-webkit-search-cancel-button{
  -webkit-appearance: none;
  height: 24px;
  width: 24px;
  margin-left: .4em;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
  cursor: pointer;
}

.databox p {
  color: var(--teksty);
}

.adm_table {
  color: var(--teksty);
  vertical-align: middle;
}

.adm_o_green {
  color: green;
}

.adm_o_red {
  color: red;
}

.adm_info {
  color: var(--teksty);
}

.adm_sbut_cont {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.adm_sbut {
  cursor: pointer;
    margin-top: 20px;
    max-width: 200px;
}


.pb_adm_wrapper {
  border: transparent;
}

.pb_adm_container {
  background-color: var(--teksty);
}

.pb_adm_barCompleted {
  background-color: var(--akcenty);
  text-align: center;
  width: 80%;
}

.pb_adm_label {
  color: var(--teksty);
}

.aut-ser-list-detail {
  color: var(--teksty);
}

/* new oceny */

.adm_oc_id {
  margin-bottom: 0;
  font-size: 0.8em;
}
.adm_oc_autor {
  font-size: 0.9em;
  color: var(--akcenty) !important;
  margin-bottom: 0;
}
.adm_oc_title {
  font-size: 1em;
  margin-bottom: 0px;
  line-height: 1em;
}
.adm_oc_basic {
  margin-bottom: 0px;
  font-size: 0.9em;
}
.adm_oc_rating {
  font-size: 0.9em;
  font-weight: bold;
  margin-bottom: 0px;
}

.adm_oc_labels {
  text-align: right;
}

.adm_oc_values {
  text-align: center;
  width: 120px;
}

.adm_oc_time_box {
  text-align: center;
}

.adm_oc_rating_value {
  border-top: solid 1px var(--teksty);
  margin-left: auto;
  margin-right: auto;
}

.transparent-text {
  color: transparent;
}

.adm_oc_onlydesktop {

}

.adm_oc_onlymobile {
  display: none;
}

.adm_oc_cover {
  max-width: 100px;
  margin-top: 5px;
  margin-bottom: 5px;
  float: right;
}

.adm_oc_cover_holder {
  width: 120px;
}

@media (max-width: 500px) {
  .adm_oc_onlydesktop {
    display: none;
  }

  .adm_oc_onlymobile {
    display: block;
  }
}

.onlytext {
  color: var(--teksty);
}

.newadmtable tr td:first-child {
  text-align: right;
  width: 80px;
}

.newadmtable tr td:nth-child(2) {
  width: 110px;
}


.newadmtable tr td:last-child {
  width: 174px;
}

.adm-ocen-but {
  cursor: pointer;
  height: 54px;
  line-height: 50px;
  /* width: inherit; */
}

.center-me-adm {
  text-align: center;
}

.admnewonmob {
  display: none;
}
@media (max-width: 500px) {
  .newadmtable tr td:first-child {
    text-align: right;
  }
  
  .newadmtable tr td:nth-child(2) {
    width: 80px;
  }
  
  
  .newadmtable tr td:last-child {
    width: 90px;
  }

  .admnewonmob {
    display: table-cell;
    width: 56px;
  }

  .admnewnomob {
    display: none;
  }

  .adm-ocen-but {
      width: 96%;
  }
}
